import React, { useState, useEffect } from 'react';
import './App.css';
import logoToolbar from './images/logo_toolbar.png'; // Ajuste o caminho conforme necessário
import imgCapaDesktop from './images/capa.webp'
import imgCapaMobile from './images/capa_mobile_1.webp'
import imgFacebook from './images/ic_facebook.png'
import imgInstagram from './images/ic_instagram.png'
import imgWhatsapp from './images/whatsapp.svg'
import imgPuraEnergia from './images/pura_energia.webp'
import imgSoFibras from './images/so_fibras.webp'


function App() {

  const [imagemCapa, setImagemCapa] = useState(imgCapaMobile);

  useEffect(() => {
    const atualizarImagemDeFundo = () => {
      if (window.innerWidth >= 768) {
        setImagemCapa(imgCapaDesktop);
      } else {
        setImagemCapa(imgCapaMobile);
      }
    };

    window.addEventListener('resize', atualizarImagemDeFundo);
    atualizarImagemDeFundo();

    return () => window.removeEventListener('resize', atualizarImagemDeFundo);
  }, []);

  return (
    <div className="app">
      <div className="toolbar">
        <img src={logoToolbar} alt="Logo" className="logo" /> {/* Ícone centralizado na toolbar */}
      </div>

      <div className="capa" style={{ backgroundImage: `url(${imagemCapa})` }}> {
        <h1 className="texto-capa">Chegamos para ajudar seu <span className="destaque">corpo</span> e trabalhar sua <span className="destaque">mente</span></h1>
      } </div>

      <div className="produto">
        <div className="card">
          <div className="card-image">
            <img src={imgPuraEnergia} alt="pura energia" />
          </div>
          <div className="card-info">
            {/* <p className="original-price">De: R$92,00</p> */}
            <p className="name-product">Pura Energia</p>
            <p className="price">R$84,90</p>
            <a href="https://meiusuplementos.mercadoshops.com.br/MLB-3655539057-pre-treino-pura-energia-sabor-guarana-laranja-_JM#position=3&search_layout=stack&type=item&tracking_id=d17e3ee5-f33c-4e87-8b6f-1f51b6a42658" target="_blank" rel="noreferrer">
              <button className="buy-button" >Comprar</button>
            </a>
          </div>
        </div>

        <div className="card" >
          <div className="card-image">
            <img src={imgSoFibras} alt="so fibras" />
          </div>
          <div className="card-info">
            {/* <p className="original-price">De: R$79,90</p> */}
            <p className="name-product">Só Fibras</p>
            <p className="price">R$67,90</p>
            <a href="https://meiusuplementos.mercadoshops.com.br/MLB-3221086967-suplemento-so-fibras-_JM#position=1&search_layout=stack&type=item&tracking_id=38562585-e586-4f05-a933-f001b201d838" target="_blank" rel="noreferrer">
              <button className="buy-button">Comprar</button>
            </a>
          </div>
        </div>
      </div>

      <div className="rodape">
        <div className='social_media'>
          <a href='https://www.facebook.com/meiusuplementos' target='_blank' rel="noopener noreferrer"><img src={imgFacebook} alt='meiu_suplemento' className='img-facebook'></img></a>
          <a href='https://instagram.com/meiusuplementos?igshid=MDE2OWE1N2Q=' target='_blank' rel="noopener noreferrer"><img src={imgInstagram} alt='meiu_suplemento' className='img-instagram'></img></a>
        </div>
        <div className='num_cel'>
          <h5> (31) 97262-4506 <br></br> meiusuplementos@gmail.com</h5>
        </div>
      </div>

      <div className="floating_btn">
        <a href="https://api.whatsapp.com/send?phone=5531972624506&text=Ol%C3%A1%2C%20Parab%C3%A9ns%20pela%20iniciativa.%20%20Estamos%20a%20um%20passo%20de%20concluir%20sua%20solicita%C3%A7%C3%A3o%20e%20transformar%20sua%20vida." target="_blank" rel="noopener noreferrer">
          <div className="contact_icon">
            <i className="fa fa-whatsapp my-float"><img src={imgWhatsapp} alt='whatsapp' /></i>
          </div>
        </a>
      </div>
    </div>
  );
}

export default App;